import { renderMarkdown } from '~/functions/markdown';
export default defineComponent({
  inheritAttrs: false,
  props: {
    userInputMapping: {
      type: Object,
      default: function _default() {}
    },
    title: {
      type: String,
      default: ''
    },
    ixId: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    questions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isMandatory: {
      type: Boolean
    },
    isEditable: {
      type: Boolean
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      renderMarkdown: renderMarkdown
    };
  }
});